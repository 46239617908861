<template lang="pug">
.brand-themes-card(
  v-if="theme.thumbnailV2 && theme.thumbnailV2.length"
  :style="background"
  :class="theme.name"
  @click="onSeeAll"
)
</template>

<script>
  export default {
    data() {
      return {
        loaded: false,
      };
    },

    props: {
      theme: {
        type: Object,
        default: () => {},
      },
      name: { type: String, required: true },
      to: { type: [String, Object] },
    },

    computed: {
      background() {
        return {
          'background-image': `url(${this.theme.thumbnailV2})`,
        };
      },
    },
    methods: {
      onSeeAll() {
        this.$emit('track', { setting: 'theme_open', options: this.name });
        this.$emit('navigateWithFilter', this.to);
      },
    },
  };
</script>

<style lang="sass" scoped>
  .brand-themes-card
    position: relative
    padding: 20px
    background-repeat: no-repeat
    background-size: cover
    background-position: left top
    border-radius: 1rem
    height: 26vw
    max-height: 20vw
    border: 1px solid #E4E3E5
    overflow: hidden
    cursor: pointer
    @media screen and (min-width: 1921px)
      max-height: 23.9375rem
</style>
