<template lang="pug">
.row
  .col
    om-heading.mb-6.font-size-3(h3) {{ $t('templateFamilies') }}
  .col-auto
    om-link.text-nowrap(
      primary
      withIconRight
      data-track-property="change:moreThemes"
      @click="onSeeAll(allThemesLink)"
    ) {{ $t('seeAllThemes') }}
  .col-12
    .theme-slider-body
      .theme-slider-body-swiper(v-swiper:swiperRefThemes="swiperOptionsThemes")
        .swiper-wrapper
          .swiper-slide(v-for="theme in showableMulticolorThemes")
            theme-card(
              :theme="theme"
              :key="theme.name"
              :name="theme.name"
              :to="getListingRoute(theme)"
              @navigateWithFilter="$emit('navigateWithFilter', $event)"
            )
      .swiper-button-next(tabindex="0" role="button" :id="`swiper-button-next-${sliderId}`")
      .swiper-right-gradient
      .swiper-button-prev(tabindex="0" role="button" :id="`swiper-button-prev-${sliderId}`")
      .swiper-left-gradient
      .swiper-pagination
</template>

<script>
  import { Swiper as SwiperClass, Navigation, Autoplay, Pagination } from 'swiper/swiper.esm';
  import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter';
  import ThemeCard from '@/components/TemplateChooser/components/cards/ThemeCard.vue';
  import { nanoid } from 'nanoid';
  import GET_TEMPLATE_THEMES from '@/graphql/GetTemplateThemes.gql';

  SwiperClass.use([Navigation, Autoplay, Pagination]);
  const SwiperComponent = getAwesomeSwiper(SwiperClass);

  const { directive } = getAwesomeSwiper(SwiperClass);

  const THEME_NAMES_ORDERED = [
    'Essential',
    'Black',
    'Pure',
    'Modern',
    'Inverse',
    'Midnight',
    'Groundy',
    'Edge',
    'Monochrome',
    'Botanic',
    'Spark',
    'Photographic',
  ];

  export default {
    components: { ThemeCard, SwiperComponent },
    directives: {
      swiper: directive,
    },
    props: {
      slidesPerView: { default: 2.25 },
      slidesPerViewTabletLg: { default: 2.25 },
      slidesPerViewTabletSm: { default: 1.75 },
      slidesPerViewMobile: { default: 1.75 },
    },

    data() {
      return {
        sliderId: nanoid(8),
        themes: [],
      };
    },
    computed: {
      allThemesLink() {
        return {
          name: 'themes-collection',
        };
      },
      showableMulticolorThemes() {
        return this.themes
          .filter(({ multicolor, thumbnail }) => multicolor && thumbnail)
          .sort((a, b) => {
            const indexA = THEME_NAMES_ORDERED.indexOf(a.name);
            const indexB = THEME_NAMES_ORDERED.indexOf(b.name);
            return indexA - indexB;
          });
      },
      swiperOptionsThemes() {
        return {
          allowTouchMove: false,
          slidesPerView: this.slidesPerViewMobile,
          spaceBetween: 24,
          autoHeight: true,
          navigation: {
            nextEl: `#swiper-button-next-${this.sliderId}`,
            prevEl: `#swiper-button-prev-${this.sliderId}`,
          },
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          breakpoints: {
            768: {
              slidesPerView: this.slidesPerViewTabletSm,
              spaceBetween: 24,
            },
            1200: {
              slidesPerView: this.slidesPerViewTabletLg,
              spaceBetween: 24,
            },
            1400: {
              slidesPerView: this.slidesPerView,
              spaceBetween: 24,
            },
          },
        };
      },
    },
    apollo: {
      themes: {
        query: GET_TEMPLATE_THEMES,
      },
    },
    methods: {
      onSeeAll(allThemesLink) {
        this.$router.push(allThemesLink);
      },
      getListingRoute(theme) {
        return {
          name: 'themes-templates',
          params: { slug: `${theme.name}-${theme._id}` },
        };
      },
    },
  };
</script>
<style lang="sass">
  @import '../../../../sass/variables/_colors'
  .theme-slider-body
    max-width: calc(100vw - 30rem)
    .swiper-button-next,
    .swiper-button-prev
      width: 2.75rem
      height: 2.75rem
      background: white
      border-radius: 100%
      box-shadow: 0 4px 16px rgba(0,0,0,0.1)
      &:after
        font-size: 14px
        color: $om-gray-700
    .swiper-button-prev
      left: -13px
    .swiper-button-next
      right: -13px
    .swiper-button-disabled
      display: none
    .template-chooser-home-wrapper
      overflow: hidden
    .swiper-pagination
      padding: 20px 0
      width: 100% !important
      left: auto
      right: auto
      margin: auto
    .swiper-pagination-bullet
      width: 12px
      height: 12px
      background: $om-gray-400
      opacity: 1
      transform: scale(1)
      margin: 0 5px
      left: auto !important
      &.swiper-pagination-bullet-active
        background: $om-orange
    .theme-slider-body-swiper
      width: 100%
      height: auto
</style>
